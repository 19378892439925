<template>
  <van-nav-bar
    :fixed='true'
    :placeholder='true'
    title="肝硬化及其并发症规范化诊疗国际高峰论坛"
    v-if="fleg"
    left-arrow
    @click-left="onClickLeft"
  >
    <template #left>
      <van-icon
        name="cross"
        size="20"
      />
    </template>
  </van-nav-bar>
</template>

<script>
import check from '../hook/check'
export default {
  name: 'Home',
  setup () {
    // 点击左侧按钮
    const onClickLeft = () => history.back()
    const { fleg } = check()
    return {
      fleg,
      onClickLeft
    }
  }
}
</script>
<style lang="less" scoped>
:deep(.van-nav-bar__content) {
  background: #ededed;
}
</style>
