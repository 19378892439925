import { ref } from 'vue'
export default function () {
  function isWeiXinBrowser () {
    // window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
    var ua = window.navigator.userAgent.toLowerCase()
    // 通过正则表达式匹配ua中是否含有MicroMessenger字符串
    // eslint-disable-next-line eqeqeq
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
      return true
    } else {
      return false
    }
  }
  function isQQBrowser () {
    var ua = navigator.userAgent.toLowerCase()
    // eslint-disable-next-line eqeqeq
    if (ua.match(/QQ/i) == 'qq') {
      return true
    } else {
      return false
    }
  }
  const fleg = ref(true)
  if (isQQBrowser() || isWeiXinBrowser()) {
    fleg.value = false
  } else {
    fleg.value = true
  }
  return {
    fleg
  }
}
