/*
 * @Author: lsh lsh@163.com
 * @Date: 2022-05-17 13:33:53
 * @LastEditors: lsh lsh@163.com
 * @LastEditTime: 2022-06-22 16:45:07
 * @FilePath: /xy/src/api/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from '../utils/request'
// 首页列表请求接口
export const indexlist = () => axios.get('cms/jilifu_article_list')
    // 分享到微信
    // eslint-disable-next-line camelcase
export const sharewx = (api_list, url) => axios.get('cms/jsconfig', { params: { api_list, url } })