import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant'
import 'vant/lib/index.css'
import './assets/css/base.css'
import 'lib-flexible/flexible.js'
// console.log(Vant)
const app = createApp(App)
app.use(Vant)
app.use(store).use(router).mount('#app')