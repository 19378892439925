<template>
  <!-- 这个是顶部导航 -->
  <headers></headers>
  <div class="box">
    <h4 class="titles">
      <img
        src="../assets/image/title.png"
        alt=""
      >
      往期回顾
    </h4>
    <ul class="list">
      <li
        v-for="item in list"
        :key="item.id"
        @click="tourls(item.jump_url)"
      >
        <div class="left">
          <img
            :src="item.cover_img"
            alt=""
          >
        </div>
        <div class="right">
          <div class="title">
            <h5>{{item.title}}</h5>
          </div>
          <div class="type">
            <img
              src="../assets/image/play.png"
              alt=""
            >
            观看回看
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import headers from '../components/header.vue'
import { indexlist, sharewx } from '../api/index.js'
import { onMounted, ref } from 'vue'
import wx from 'weixin-js-sdk'
export default {
  components: {
    headers
  },
  setup (props) {
    const list = ref([])
    const getlist = async () => {
      const res = await indexlist()
      // console.log(res);
      if (res.code === 1) {
        list.value = res.data.data
      }
    }
    const tourls = (url) => {
      window.open(url)
    }
    getlist()
    // 这里是分享
    // 分享的链接 
    // var url = window.location.href
    var url = 'http://future.medsky.cn/#/'
    onMounted(() => {
      share()
    })
    const dsc = ref('')
    const img = ref('')
    async function share () {
      const res = await sharewx(['updateAppMessageShareData', 'updateTimelineShareData'], url)
      if (res.code === 1) {
        // console.log(res.data)
        dsc.value = res.data.share_title
        img.value = res.data.share_img
        wx.config({
          debug: false,
          appId: res.data.appid, // 和获取Ticke的必须一样------必填，公众号的唯一标识
          timestamp: res.data.timestamp, // 必填，生成签名的时间戳
          nonceStr: res.data.noncestr, // 必填，生成签名的随机串
          signature: res.data.signature, // 必填，签名，见附录1
          // 需要分享的列表项:发送给朋友，分享到朋友圈，分享到QQ，分享到QQ空间
          jsApiList: res.data.jsApiList
        })
        // classfiy.value[active.value].name
        // 分享到朋友圈
        wx.ready(function () {
          wx.updateAppMessageShareData({
            title: '向未来诊疗培训', // 分享标题
            desc: dsc.value, // 分享描述
            link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
            imgUrl: img.value, // 分享图标
            success: function () {
              // 设置成功
              console.log('a')
            }
          })
          wx.updateTimelineShareData({
            title: '向未来诊疗培训', // 分享标题·
            desc: dsc.value, // 分享描述
            link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
            imgUrl: img.value, // 分享图标
            success: function () {
              // 设置成功
            }
          })
        })
        wx.error(function (res) {
        })
      }
    }
    return {
      list,
      tourls
    }
  }

}
</script>
<style lang="less" scoped>
.box {
  padding: 0 15px;
  .titles {
    font-size: 18px;
    color: #000000;
    font-weight: 500;
    margin-top: 18px;
    display: flex;
    align-items: center;
    img {
      width: 18px;
      margin-right: 7px;
    }
  }
  .list {
    margin-top: 8px;
    li {
      width: 100%;
      display: flex;
      margin-top: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid #f6f6f6;
      &:last-child {
        border-bottom: 0px none;
      }
      .left {
        width: 123px;
        height: 70px;
        border-radius: 5px;
        overflow: hidden;
        margin-top: 3px;
        img {
          width: 100%;
        }
      }
      .right {
        margin-left: 18px;
        flex: 1;
        .title {
          height: 50px;
          overflow: hidden;
          h5 {
            font-size: 16px;
            font-weight: 400;
            line-height: 23px;
            color: #353535;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }
        }
        .type {
          font-size: 12px;
          color: #bdbdbd;
          display: flex;
          align-items: center;
          margin-top: 6px;
          img {
            width: 10px;
            margin-right: 6px;
            margin-top: 2px;
          }
        }
      }
    }
  }
}
</style>
