import axios from 'axios'
const instance = axios.create({
    baseURL: ' http://yxy.medsky.cn/api/',
    timeout: 10000
})

// 添加请求拦截器
instance.interceptors.request.use(function(config) {
    // 在发送请求之前做些什么
    // console.log('全局', config)
    return config
}, function(error) {
    // 对请求错误做些什么
    return Promise.reject(error)
})

// 添加响应拦截器
instance.interceptors.response.use(function(response) {
    // 对响应数据做点什么 
    const res = response.data
    return res
}, function(error) {
    // 对响应错误做点什么  
    return Promise.reject(error)
})
export default instance